:root {
    --viewHeight: 100vh;
    --horizontalScrollText: 0;
    --animation-duration: 8s;
}


html, body {
    scroll-behavior: smooth !important;
    -webkit-overflow-scrolling: touch !important;
}
