/* ======= Maax ====== */

@font-face {
    font-family: 'Maax';
    font-style: normal;
    src: local('Maax'),
    url('./Maax/Maax.woff2') format('woff2'),
    url('./Maax/Maax.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Maax-Medium';
    font-style: normal;
    src: local('Maax-Medium'),
    url('./Maax/Maax-Medium.woff2') format('woff2'),
    url('./Maax/Maax-Medium.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Maax-Bold';
    font-style: normal;
    src: local('Maax-Bold'),
    url('./Maax/Maax-Bold.woff2') format('woff2'),
    url('./Maax/Maax-Bold.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Maax-RegularMono';
    font-style: normal;
    src: local('Maax-RegularMono'),
    url('./Maax/Maax-RegularMono.woff2') format('woff2'),
    url('./Maax/Maax-RegularMono.woff') format('woff');
    font-display: swap;
}



/* ======= Charter ====== */

@font-face {
    font-family: 'Charter-Regular';
    src:  local('Charter-Regular'),
    url('./Charter/Charter-Regular.woff2') format('woff2'),
    url('./Charter/Charter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Charter-Bold';
    font-style: normal;
    src:  local('Charter-Bold'),
    url('./Charter/Charter-Bold.woff2') format('woff2'),
    url('./Charter/Charter-Bold.woff') format('woff');
    font-display: swap;
    font-weight:bold;
}

@font-face {
    font-family: 'Charter-Italic';
    font-style: normal;
    src:  local('Charter-Italic'),
    url('./Charter/Charter-Italic.woff2') format('woff2'),
    url('./Charter/Charter-Italic.woff') format('woff');
    font-display: swap;
    font-style: italic;
} 


/* ======= TT Hovers ====== */

@font-face {
    font-family: 'TT-Hoves';
    src: local('TT-Hoves'),
    url('./TTHover/tt-hoves-light.woff2') format('woff2'),
    url('./TTHover/tt-hoves-light.woff') format('woff');
    font-weight: lighter;
    font-display: swap;
    /* unicode-range: U+0000-00FF; */
}

@font-face {
    font-family: 'TT-Hoves-Light';
    src: local('TT-Hoves-Light'), 
    url('./TTHover/tt-hoves-hairline.woff2') format('woff2'),  
    url('./TTHover/tt-hoves-hairline.woff') format('woff');
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: 'TT-Hoves-Thin';
    src: local('TT-Hoves-Thin'), 
    url('./TTHover/tthoves-thin.woff2') format('woff2'),  
    url('./TTHover/tthoves-thin.woff') format('woff');
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: 'TT-Hoves-Regular';
    src: local('TT-Hoves-Regular'),
    url('./TTHover/tthoves-regular.woff2') format('woff2'),  
    url('./TTHover/tthoves-regular.woff') format('woff');
    font-weight: 400;
    font-display: swap;
} 

@font-face {
    font-family: 'TT-Hoves-Medium';
    src: local('TT-Hoves-Medium'),
    url('./TTHover/tthoves-medium.woff2') format('woff2'),  
    url('./TTHover/tthoves-medium.woff') format('woff');
    font-display: swap;
} 

@font-face {
    font-family: 'TT-Hoves-Bold';
    src: local('TT-Hoves-Bold'),
    url('./TTHover/TTHoves-Bold.woff2') format('woff2'),  
    url('./TTHover/TTHoves-Bold.woff') format('woff');
    font-weight: bold;
    font-display: swap;
} 


/* ======= Iskry ====== */

@font-face {
    font-family: 'Iskry-Regular';
    font-style: normal;
    src: local('Iskry-Regular'),
    url('./Iskry/Iskry-Regular.woff2') format('woff2'),  
    url('./Iskry/Iskry-Regular.woff') format('woff');
    font-display: swap;
}
